import React from 'react';
import { Global, css } from '@emotion/core';
import { Helmet } from 'react-helmet'
import Header from './header';

const Layout = ({ children, lan } ) => {

    return ( 
        <>
            <Global
                styles={css`
                    html {
                        font-size: 62.5%;
                        box-sizing: border-box;
                    }
                    *, *:before, *:after {
                        box-sizing: inherit;
                    }
                    body{
                        font-size: 1.6rem;
                        line-height: 2;
                        font-family: 'Lato', sans-serif;
                    }
                    h1, h2, h3 {
                        margin: 0;
                        line-height: 1.5;
                    }
                    h1, h2 {
                        text-align: center;
                        font-family: 'Lato', sans-serif;
                        font-weight: 300;
                    }
                    h3 {
                        font-family: 'Roboto', sans-serif;
                    }
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;    
                    }
                    .contenedor {
                        max-width: 120rem;
                        margin: 0 auto;
                        width: 95%;
                    }
                    img {
                        max-width: 100%;
                    }
                `}
            />            
            <Helmet>
                <html lang={lan} />
                <script>  
                    { `var _smartsupp = _smartsupp || {};
                        _smartsupp.key = '60d248db50295482f3df1c881511c868ed7f9d9d';
                        setTimeout(function(){ 
                        console.log('')
                        window.smartsupp || (function(d) {
                            var s,c,o=smartsupp=function() {
                            o._.push(arguments)
                            };
                            o._=[];
                            s=d.getElementsByTagName('script')[0];
                            c=d.createElement('script');
                            c.type='text/javascript';
                            c.charset='utf-8';c.async=true;
                            c.src='//www.smartsuppchat.com/loader.js?';
                            s.parentNode.insertBefore(c,s);
                        })(document);
                        }, 20000)
                    `}          
                </script>
            </Helmet>       
            <Header lan={lan} />
            
            {children}
        </>
     );
}
 
export default Layout;